import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Grid, Box, useTheme } from "@material-ui/core";
import PrimaryButtonUko from './primaryButtonUko.component';
import { isEmpty } from "../utils/proprietaryHooks";
import { getVersionAppAction } from '../actions/applications.action';

/**
 * AlertUko constant ( function component used as AlertUko )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */
const minWidth = 285;
const maxWidth = 320;
const useStyles = makeStyles((theme) => ({
    root: {
        width: "285px",
        minWidth: minWidth,
        maxWidth: maxWidth,
        margin: "0 auto",
        padding: "20px",
        borderRadius: 12
    },
    message: {
        color: theme.alertUko.colorTextUko,
        padding: "10px",
        textAlign: "center",
        fontSize: "16px",
    },
    version: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme.versionUko.colorVersionLabel,
        position: 'absolute',
        bottom: '15px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            position: 'static',
        },
    },
    buttonSize: {
        width: "390px",
        [theme.breakpoints.down('xs')]: {
            width: "300px",
        },
    },
}));

const AlertUko = ({ ...props }) => {
    const { logo, image, value, onClick, getVersionApp, getVersionAppAction, appName } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [t, i18n] = useTranslation("global");
    const [versionMessage, setVersionMessage] = useState("");

    useEffect(() => {
        getVersionAppAction(appName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isEmpty(getVersionApp)) {
            setVersionMessage(getVersionApp.version);
        }
    }, [getVersionApp]);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh', backgroundColor: theme.palette.secondary.light, gap: "16px" }}>
            <Box m={1} pt={3}>
                <img src={logo} alt="Logo" width="115.71px" />
            </Box>
            {
                image && (
                    <Box m={1} pt={3}>
                        <img src={image} alt="Logo" width="230.71px" />
                    </Box>
                )
            }
            <Typography className={classes.message}>{value}</Typography>
            <Box m={1} pt={3} className={classes.buttonSize} >
                <PrimaryButtonUko
                    txtBtn={appName === 'bemyself' ? t("alert-message.button") : "Aceptar"}
                    onClick={onClick}
                />
            </Box>
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'space-between', marginTop: '24px', marginBottom: '24px' }} ></div>
            <Box className={classes.version}>
                <p>{versionMessage}</p>
            </Box>
        </Grid>
    );
};

AlertUko.propTypes = {
};
const mapStateToProps = (state) => ({
    getVersionApp: state.applicationsReducer.getVersionApp,
});
export default connect(mapStateToProps, {
    getVersionAppAction,
})(AlertUko);
