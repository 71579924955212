import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  useTheme,
  Grid,
  Typography,
} from "@material-ui/core";
import Box from '@material-ui/core/Box';

import AuthenticationLayoutUkoBeMyself from "../../../components/AuthenticationLayoutUkoBeMyself/AuthenticationLayoutUkoBeMyself";
import FullLoader from "../../../components/Loader/FullLoader.component";
import { getVersionAppAction } from "../../../actions/applications.action";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "../../../utils/proprietaryHooks";

const SendEmailResetPasswordUko = (props) => {  
  const {
    history,
    appName,
    getVersionAppAction,
    getVersionApp,
    tenant,
  } = props;

  const [maskedEmail, setMaskedEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [versionMessage, setVersionMessage] = useState("");
  const classes = useStyles();
  const theme = useTheme();
  const [t, i18n] = useTranslation("global");
  
  /**
   * useefect load inicial componente SendEmailResetPasswordUko
   */
  useEffect(() => {
    getVersionAppAction(appName, tenant);
    if (history.location.state) {
      setMaskedEmail(history.location.state.maskedEmail);
    } else {
      setIsLoading(true);
      history.push('/Login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(getVersionApp)) {
      setVersionMessage(getVersionApp.version);
    }
  }, [getVersionApp]);

  return (
    <>
      {isLoading === true ? (<FullLoader open={isLoading} />) : (
        <AuthenticationLayoutUkoBeMyself title={t("review-email.label-review")} logoApp={theme.urlLogo.urlCheckEmaillogo} logoSignIn={theme.urlLogo.urlSignIn} >        
          <Grid container direction="column" className={classes.gridContainer}>          
            <Grid item xs={12} className={classes.gridItem}>            
              <Box>
                <Typography className={classes.sendEmailMessage}>{t("review-email.description-review.description-one")}<strong>{maskedEmail}</strong>{t("review-email.description-review.description-two")}</Typography>
              </Box>
            </Grid>
          </Grid>          
          <div classes={classes.divSpace}></div>
          <Grid item xs={12} className={`${classes.gridItem} ${classes.gridReset}`}>
            <Typography
              className={classes.typographyGoBack}
              >
              <Link
                to="/login"
                className={classes.linkGoBack}
              >
                {t("review-email.link-login")}
              </Link>
            </Typography>
          </Grid>
          <Box className={classes.version}>
            <p>{versionMessage}</p>
          </Box>
        </AuthenticationLayoutUkoBeMyself>
      )
      }
    </>
  )
};

SendEmailResetPasswordUko.propTypes = {
};

const mapStateToProps = (state) => ({ 
  getVersionApp: state.applicationsReducer.getVersionApp,
});

const mapDispatchToProps = {
  getVersionAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailResetPasswordUko);

const useStyles = makeStyles((theme) => ({
  typeInput: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '8px',
      width: '486px',
      height: '56px',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.InputUko.colorInputLetter,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      borderColor: theme.InputUko.borderInput,
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      color: theme.InputUko.colorLabel,
      fontSize: '17px',
      marginTop: '7px',
    },
    "& .MuiInputLabel-root.Mui-focused": {
      fontWeight: 600,
      color: theme.InputUko.colorLabelFocus,
    },
    "& .MuiSvgIcon-root": {
      color: theme.InputUko.colorIcon,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.dark,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.error.dark,
    },
    "& .MuiInputLabel-shrink": {
      margin: "0 auto",
      position: "absolute",
      top: "1px",
    },
  },
  gridReset: {
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  gridContainer: {
    gap: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  gridItem: { 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center" 
  },
  divSpace: { 
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between', 
    marginTop: '24px',
    marginBottom: '24px'
  },
  sendEmailMessage: {
    color: theme.typography.colorMessageUko,
    fontSize: '15px',
  },
  typographyGoBack: { 
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1.6,
    color: theme.linkUko.colorLink,
    paddingLeft: '8px',
    paddingRight: '8px',
    marginTop: '15px'
  },
  linkGoBack: {     
    textDecoration: "underline",
    color: theme.linkUko.colorLink,
    marginBottom: '2px',
    fontSize: '15px',
    fontWeight: 700
  },  
  version: {
    fontSize: '14px',
    fontWeight: 600,    
    color: theme.versionUko.colorVersionLabel,
    position: 'absolute',
    bottom: '15px',
    left: '0',
    right: '0',
    textAlign: 'center',
  }
}));

