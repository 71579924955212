export const themeColors = {
  colfactura: {
    tenantLanguage: "es",
    primary: {
      light: "#50CBFF",
      main: "#025BA5",
      dark: "#025BA5",
      iconSend: "#16B1F3",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
      light: "#FA5A0026",
      titleText: "",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlChangePasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  colnomina: {
    tenantLanguage: "es",
    primary: {
      light: "#50CBFF",
      main: "#025BA5",
      dark: "#025BA5",
      iconSend: "#16B1F3",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
      light: "#FA5A0026",
      titleText: "",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlChangePasswordlogo: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  firmaya: {
    tenantLanguage: "es",
    primary: {
      light: "#FFAB8C",
      main: "#FA5A00",
      dark: "#E55200",
      iconSend: "#E55200",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
      light: "#FA5A0026",
      titleText: "",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlChangePasswordlogo: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  ibuhoo: {
    tenantLanguage: "es",
    primary: {
      light: "#009BDD",
      main: "#1D71B8",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
      light: "#FA5A0026",
      titleText: "",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    text: {
      primary: "#009BDD",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlChangePasswordlogo: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  license: {
    tenantLanguage: "es",
    primary: {
      light: "#009BDD",
      main: "#1D71B8",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
      light: "#FA5A0026",
      titleText: "",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlChangePasswordlogo: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  "GSE Suite": {
    tenantLanguage: "es",
    primary: {
      light: "#009BDD",
      main: "#1D71B8",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
    },
    info: {
      light: "#00000026",
      main: "#00000080",
      dark: "#00000099",
    },
    text: {
      primary: "#009BDD",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlChangePasswordlogo: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  oidc: {
    tenantLanguage: "es",
    primary: {
      light: "#009BDD",
      main: "#1D71B8",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
    },
    info: {
      light: "#00000026",
      main: "#00000080",
      dark: "#00000099",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlChangePasswordlogo: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  biometria: {
    tenantLanguage: "es",
    primary: {
      light: "#009BDD",
      main: "#1D71B8",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
      light: "#FA5A0026",
      titleText: "",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlChangePasswordlogo: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  confronta: {
    tenantLanguage: "es",
    primary: {
      light: "#009BDD",
      main: "#1D71B8",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#FA5A00",
      light: "#FA5A0026",
      titleText: "",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    urlLogo: {
      url: "",
      urlSignIn: "",
      urlRememberPasswordlogo: "",
      urlCheckEmaillogo: "",
      urlCheckEmailMoreBlue: "",
      urlInvalidLinkMoreBlue: "",
      urlRemeberAndInvalid: "",
      urlExpireTime: "",
      urlChangePasswordlogo: "",
      urlSuccessAlertlogo: "",
      urlCheckEmailAgain: "",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "",
      borderInput: "",
      colorLabel: "",
      colorLabelFocus: "",
      colorIcon: "",
      backgroundColor: "",
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "",
      buttonUkoColorDisabled: "",
      buttonUkoBackgroundActive: "",
      buttonUkoBackgroundSuccess: "",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "",
    },
    typographyMessageUko: {
      textColor: '',
    },
    versionUko: {
      colorVersionLabel: ""
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  myselfcert: {
    tenantLanguage: "es",
    primary: {
      light: "#8AB9F2",
      main: "#2499EF",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "rgba(52, 73, 94, 1)",
      light: "#F5F8FA",
      titleText: "#121F43"
    },
    info: {
      light: "#335474",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FF316F",
      main: "#FF316F1A",
      dark: "#FF316F",
    },
    urlLogo: {
      url: "LogosUko//LoginUko.png", 
      urlSignIn: "LogosUko//LogoSignInUko.png",
      urlRememberPasswordlogo: "LogosUko//RememberPasswordUko.png",
      urlCheckEmaillogo: "LogosUko//CheckEmail.png",
      urlCheckEmailMoreBlue: "LogosUko//CheckEmailMoreBlue.png",
      urlInvalidLinkMoreBlue: "LogosUko//InvalidLinkMoreBlue.png",
      urlRemeberAndInvalid: "LogosUko//RememberPasswordInvalidUrl.png",
      urlExpireTime: "LogosUko//ExpireTime.png",
      urlChangePasswordlogo: "LogosUko//ChangePassword.png",
      urlSuccessAlertlogo: "LogosUko//PasswordUpdated.png",
      urlCheckEmailAgain: "LogosUko//CheckEmailAgain.png",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "#011E3D",
      borderInput: "#738499",
      colorLabel: "#738499",
      colorLabelFocus: "#2499EF",
      colorIcon: "#738499",
      backgroundColor: "#FFFFFF"
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "#DCE5F0",
      buttonUkoColorDisabled: "#A7BEDA !important",
      buttonUkoBackgroundActive: "#34495E",
      buttonUkoBackgroundSuccess: "#FFFFFF",
      buttonUkoColorDisabledResetPassword: "",
    },
    linkUko: {
      colorLink: "#2499EF",
    },
    typographyMessageUko: {
      textColor: '#335474',
    },
    versionUko: {
      colorVersionLabel: "#335474"
    },
    alertUko: {
      imageUko: "LogosUko//alertUko.png",
      colorTextUko: "#335474",
    },
    forgotPassword: {
      colorForgotPassword: "",
    },
    selectAccount: {
      colorRoot: "",
      colorChecked: "",
    },
    widthImageLogo: "",
  },
  bemyself: {
    tenantLanguage: "es",
    primary: {
      light: "#293233",
      main: "#09C3D1",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#E1FF2B !important",
      light: "#0C0C0C",
      titleText: "#F6F7F9"
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
      // light: "#EBECF3",
    },
    error: {
      light: "#FEA3B1",
      main: "#8A113A",
      dark: "#FEA3B1",
    },
    urlLogo: {
      url: "LogosUko//LoginUko.png",
      urlSignIn: "LogosUko//LogoBeMyself.svg",
      urlRememberPasswordlogo: "LogosUko//RememberPasswordUko.png",
      urlCheckEmaillogo: "LogosUko//CheckEmail.png",
      urlCheckEmailMoreBlue: "LogosUko//CheckEmailMoreBlue.png",
      urlInvalidLinkMoreBlue: "LogosUko//InvalidLinkMoreBlue.png",
      urlRemeberAndInvalid: "LogosUko//RememberPasswordInvalidUrl.png",
      urlExpireTime: "LogosUko//ExpireTime.png",
      urlChangePasswordlogo: "LogosUko//ChangePassword.png",
      urlSuccessAlertlogo: "LogosUko//PasswordUpdated.png",
      urlCheckEmailAgain: "LogosUko//CheckEmailAgain.png",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "#BEC8C9",
      borderInput: "#6F797A",
      colorLabel: "#BEC8C9",
      colorLabelFocus: "#09C3D1",
      colorIcon: "#B2B3BD",
      backgroundColor: "#0C0C0C"
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "#293233",
      buttonUkoColorDisabled: "#75788A !important",
      buttonUkoBackgroundActive: "#09C3D1",
      buttonUkoBackgroundSuccess: "#141D1E",
      buttonUkoColorDisabledResetPassword: "#3F4849 !important",
    },
    linkUko: {
      colorLink: "#D5F129",
    },
    typographyMessageUko: {
      textColor: '#EBECF3',
    },
    versionUko: {
      colorVersionLabel: "#566061"
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "#ADB3CC",
    },
    forgotPassword: {
      colorForgotPassword: "#E1FF2B",
    },
    selectAccount: {
      colorRoot: "#09C3D1",
      colorChecked: "#09C3D1",
    },
    widthImageLogo: "220px",
  },
  bemyself_472: {
    tenantLanguage: "es",
    primary: {
      light: "#293233",
      main: "#00A3DA",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#E1FF2B !important",
      light: "#0C0C0C",
      titleText: "#F6F7F9"
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
      // light: "#EBECF3",
    },
    error: {
      light: "#FEA3B1",
      main: "#8A113A",
      dark: "#FEA3B1",
    },
    urlLogo: {
      url: "LogosUko//LoginUko.png",
      urlSignIn: "LogosUkoMyself//Tenants//472.png",
      urlRememberPasswordlogo: "LogosUko//RememberPasswordUko.png",
      urlCheckEmaillogo: "LogosUko//CheckEmail.png",
      urlCheckEmailMoreBlue: "LogosUko//CheckEmailMoreBlue.png",
      urlInvalidLinkMoreBlue: "LogosUko//InvalidLinkMoreBlue.png",
      urlRemeberAndInvalid: "LogosUko//RememberPasswordInvalidUrl.png",
      urlExpireTime: "LogosUko//ExpireTime.png",
      urlChangePasswordlogo: "LogosUko//ChangePassword.png",
      urlSuccessAlertlogo: "LogosUko//PasswordUpdated.png",
      urlCheckEmailAgain: "LogosUko//CheckEmailAgain.png",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "#BEC8C9",
      borderInput: "#6F797A",
      colorLabel: "#BEC8C9",
      colorLabelFocus: "#00A3DA",
      colorIcon: "#B2B3BD",
      backgroundColor: "#0C0C0C"
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "#293233",
      buttonUkoColorDisabled: "#75788A !important",
      buttonUkoBackgroundActive: "#00A3DA",
      buttonUkoBackgroundSuccess: "#141D1E",
      buttonUkoColorDisabledResetPassword: "#3F4849 !important",
    },
    linkUko: {
      colorLink: "#D5F129",
    },
    typographyMessageUko: {
      textColor: '#EBECF3',
    },
    versionUko: {
      colorVersionLabel: "#566061"
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "#ADB3CC",
    },
    forgotPassword: {
      colorForgotPassword: "#E1FF2B",
    },
    selectAccount: {
      colorRoot: "#BEC8C9",
      colorChecked: "#00A3DA",
    },
    widthImageLogo: "150px",
  },
  bemyself_bmscol: {
    tenantLanguage: "es",
    primary: {
      light: "#293233",
      main: "#09C3D1",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#E1FF2B !important",
      light: "#0C0C0C",
      titleText: "#F6F7F9"
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
      // light: "#EBECF3",
    },
    error: {
      light: "#FEA3B1",
      main: "#8A113A",
      dark: "#FEA3B1",
    },
    urlLogo: {
      url: "LogosUko//LoginUko.png",
      urlSignIn: "LogosUko//LogoBeMyself.svg",
      urlRememberPasswordlogo: "LogosUko//RememberPasswordUko.png",
      urlCheckEmaillogo: "LogosUko//CheckEmail.png",
      urlCheckEmailMoreBlue: "LogosUko//CheckEmailMoreBlue.png",
      urlInvalidLinkMoreBlue: "LogosUko//InvalidLinkMoreBlue.png",
      urlRemeberAndInvalid: "LogosUko//RememberPasswordInvalidUrl.png",
      urlExpireTime: "LogosUko//ExpireTime.png",
      urlChangePasswordlogo: "LogosUko//ChangePassword.png",
      urlSuccessAlertlogo: "LogosUko//PasswordUpdated.png",
      urlCheckEmailAgain: "LogosUko//CheckEmailAgain.png",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "#BEC8C9",
      borderInput: "#6F797A",
      colorLabel: "#BEC8C9",
      colorLabelFocus: "#09C3D1",
      colorIcon: "#B2B3BD",
      backgroundColor: "#0C0C0C"
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "#293233",
      buttonUkoColorDisabled: "#75788A !important",
      buttonUkoBackgroundActive: "#09C3D1",
      buttonUkoBackgroundSuccess: "#141D1E",
      buttonUkoColorDisabledResetPassword: "#3F4849 !important",
    },
    linkUko: {
      colorLink: "#D5F129",
    },
    typographyMessageUko: {
      textColor: '#EBECF3',
    },
    versionUko: {
      colorVersionLabel: "#ADB3CC"
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "#ADB3CC",
    },
    forgotPassword: {
      colorForgotPassword: "#E1FF2B",
    },
    selectAccount: {
      colorRoot: "#09C3D1",
      colorChecked: "#09C3D1",
    },
    widthImageLogo: "220px",
  },
  bemyself_bmsecu: {
    tenantLanguage: "es",
    primary: {
      light: "#293233",
      main: "#09C3D1",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#E1FF2B !important",
      light: "#0C0C0C",
      titleText: "#F6F7F9"
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
      // light: "#EBECF3",
    },
    error: {
      light: "#FEA3B1",
      main: "#8A113A",
      dark: "#FEA3B1",
    },
    urlLogo: {
      url: "LogosUko//LoginUko.png",
      urlSignIn: "LogosUko//LogoBeMyself.svg",
      urlRememberPasswordlogo: "LogosUko//RememberPasswordUko.png",
      urlCheckEmaillogo: "LogosUko//CheckEmail.png",
      urlCheckEmailMoreBlue: "LogosUko//CheckEmailMoreBlue.png",
      urlInvalidLinkMoreBlue: "LogosUko//InvalidLinkMoreBlue.png",
      urlRemeberAndInvalid: "LogosUko//RememberPasswordInvalidUrl.png",
      urlExpireTime: "LogosUko//ExpireTime.png",
      urlChangePasswordlogo: "LogosUko//ChangePassword.png",
      urlSuccessAlertlogo: "LogosUko//PasswordUpdated.png",
      urlCheckEmailAgain: "LogosUko//CheckEmailAgain.png",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "#BEC8C9",
      borderInput: "#6F797A",
      colorLabel: "#BEC8C9",
      colorLabelFocus: "#09C3D1",
      colorIcon: "#B2B3BD",
      backgroundColor: "#0C0C0C"
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "#293233",
      buttonUkoColorDisabled: "#75788A !important",
      buttonUkoBackgroundActive: "#09C3D1",
      buttonUkoBackgroundSuccess: "#141D1E",
      buttonUkoColorDisabledResetPassword: "#3F4849 !important",
    },
    linkUko: {
      colorLink: "#D5F129",
    },
    typographyMessageUko: {
      textColor: '#EBECF3',
    },
    versionUko: {
      colorVersionLabel: "#ADB3CC"
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "#ADB3CC",
    },
    forgotPassword: {
      colorForgotPassword: "#E1FF2B",
    },
    selectAccount: {
      colorRoot: "#09C3D1",
      colorChecked: "#09C3D1",
    },
    widthImageLogo: "220px",
  },
  bemyself_bmsbra: {
    tenantLanguage: "pt",
    primary: {
      light: "#293233",
      main: "#09C3D1",
      dark: "#003582",
      iconSend: "#003582",
    },
    secondary: {
      dark: "#E45501",
      main: "#E1FF2B !important",
      light: "#0C0C0C",
      titleText: "#F6F7F9"
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
      // light: "#EBECF3",
    },
    error: {
      light: "#FEA3B1",
      main: "#8A113A",
      dark: "#FEA3B1",
    },
    urlLogo: {
      url: "LogosUko//LoginUko.png",
      urlSignIn: "LogosUko//LogoBeMyself.svg",
      urlRememberPasswordlogo: "LogosUko//RememberPasswordUko.png",
      urlCheckEmaillogo: "LogosUko//CheckEmail.png",
      urlCheckEmailMoreBlue: "LogosUko//CheckEmailMoreBlue.png",
      urlInvalidLinkMoreBlue: "LogosUko//InvalidLinkMoreBlue.png",
      urlRemeberAndInvalid: "LogosUko//RememberPasswordInvalidUrl.png",
      urlExpireTime: "LogosUko//ExpireTime.png",
      urlChangePasswordlogo: "LogosUko//ChangePassword.png",
      urlSuccessAlertlogo: "LogosUko//PasswordUpdated.png",
      urlCheckEmailAgain: "LogosUko//CheckEmailAgain.png",
      urlMaintenanceImage: "maintenanceImage.png",
      urlWhiteGseLogo: "whiteGseLogo.png",
    },
    InputUko: {
      colorInputLetter: "#BEC8C9",
      borderInput: "#6F797A",
      colorLabel: "#BEC8C9",
      colorLabelFocus: "#09C3D1",
      colorIcon: "#B2B3BD",
      backgroundColor: "#0C0C0C"
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: "#293233",
      buttonUkoColorDisabled: "#75788A !important",
      buttonUkoBackgroundActive: "#09C3D1",
      buttonUkoBackgroundSuccess: "#141D1E",
      buttonUkoColorDisabledResetPassword: "#3F4849 !important",
    },
    linkUko: {
      colorLink: "#D5F129",
    },
    typographyMessageUko: {
      textColor: '#EBECF3',
    },
    versionUko: {
      colorVersionLabel: "#ADB3CC"
    },
    alertUko: {
      imageUko: "",
      colorTextUko: "#ADB3CC",
    },
    forgotPassword: {
      colorForgotPassword: "#E1FF2B",
    },
    selectAccount: {
      colorRoot: "#09C3D1",
      colorChecked: "#09C3D1",
    },
    widthImageLogo: "220px",
  },
};

/*
  primary -> light: #009BDD / main: #1D71B8  / dark: #003582
  secondary -> light: #FA5A00  / main: #FA5A00  / dark:  #E45501
  error -> light:  / main:  / dark:
  warning -> light:  / main:  / dark:
  info -> light:  #00000026 / main: #00000080 / dark:  #00000099
  success -> light:  / main:  / dark:
 */
