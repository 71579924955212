import { useEffect, useRef, useState } from "react";

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn("error configurando storage", error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue];
};

export const isEqual = (obj1, obj2) => {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;
  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
    );
  }
  return false;
};


/**
 * returns array compared ignoring order
 * @author Kevin_Olarte
 * @param {array} originalArray 
 * @param {array} currentArray 
 * @return {boolean} equality
 */
export const isArrayEqual = (array1, array2) => {

  if (array1.length !== array2.length) return false;
  const uniqueValues = new Set([...array1, ...array2]);
  for (const v of uniqueValues) {
    const count1 = array1.filter(e => deepEqual(e, v)).length;
    const count2 = array2.filter(e => deepEqual(e, v)).length;
    if (count1 !== count2) return false;
  }
  return true
}

let isEqualMenus;

/**
 * Compare all elements with their childrens
 * @param {*} menu1 
 * @param {*} menu2 
 * @returns false if any menu.key is diferent
 */
export const compareMenus = (menu1, menu2) => {
  isEqualMenus = true;
  recursiveCall(menu1, menu2)
  return isEqualMenus;
}

/**
 * This method change isEqualsMenu id any key is different
 * @param {*} menu1 
 * @param {*} menu2 
 * @returns 
 */
const recursiveCall = (menu1, menu2) => {
  if (menu1.length === menu2.length) {
    for (let i = 0; i < menu1.length; i++) {
      const element = menu1[i];
      const element2 = menu2[i];
      if (element.key !== element2.key) {
        isEqualMenus = false;
        return;
      }
      if (element.children.length > 0) {
        compareMenus(element.children, element2.children);
      }
    }
  } else {
    isEqualMenus = false;
    return;
  }
}

/**
 * isEmpty constant function (verify if value is undefined, null, void string, or void array)
 *
 * @export constant
 * @param {*} value
 * @returns boolean
 */
export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);


export const isEmptyObject = (object) => {
  for (const key in object) {
    if (isEmpty(object[key])) {
      return true;
    }
  }
  return false;
}

export const haveValuesObject = (object) => {

  for (const key in object) {
    if (isEmpty(object[key])) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const toPascalCase = (item) => {
  return item.replace(
    /(\w)(\w*)/g,
    (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
  );
};

export const findFinalOfWord = (word, index, key) => {

  const finalIndex = regexIndexOf(word, key, index)
  if (finalIndex <= index && word.length <= index) {
    return word
  } else {

    return word.slice(0, finalIndex);
  }
}

const regexIndexOf = (string, regex, startPos) => {
  var indexOf = string.substring(startPos || 0).search(regex);
  return (indexOf >= 0) ? (indexOf + (startPos || 0)) : indexOf;
}

export const formatTimeUnit = (timeUnit) => {
  return timeUnit === 1 ? 'm' : timeUnit === 2 ? 'h' : 'd'
}

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    const areArrays = Array.isArray(val1) && Array.isArray(val2);
    if (areArrays) {
      const resp = isArrayEqual(val1.sort(), val2.sort())
      return resp;
    } else if (
      (areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object' && !Array.isArray(object);
}

export const validateInfoApplication = (obj1, obj2, properties) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return true;
  }

  for (const key of properties) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }

  return false;
}

export const validateEquals = (obj1, obj2) => {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;
  if (obj1Length === 0 && obj2Length === 0) {
    return true;
  } else {
    if (obj1Length === obj2Length) {
      const keys = Object.keys(obj1);
      if (keys.length !== 0) {
        for (let i = 0; i < keys.length; i++) {
          if (typeof obj1[keys[i]] === "object" || typeof obj2[keys[i]] === "object") {
            if (obj1[keys[i]].length !== undefined) {
              if (obj1[keys[i]].length !== obj2[keys[i]].length) {
                return false;
              } else {
                obj1[keys[i]].sort();
                obj2[keys[i]].sort();
                for (let j = 0; j < obj1[keys[i]].length; j++) {
                  if (typeof obj1[keys[i]][j] === "object" || typeof obj2[keys[i]][j] === "object") {
                    if (!validateEquals(obj1[keys[i]][j], obj2[keys[i]][j])) {
                      return false;
                    }
                  } else if (obj1[keys[i]][j] !== obj2[keys[i]][j]) {
                    return false;
                  }
                }
              }
            } else {
              if (!validateEquals(obj1[keys[i]], obj2[keys[i]])) {
                return false;
              }
            }
          } else if (obj1[keys[i]] !== obj2[keys[i]]) {
            return false;
          }
        }
        return true;
      }
    }
  }
};

export const useStorageWatcher = (key) => {
  const [val, setVal] = useState(localStorage.getItem(key));

  useEffect(() => {
    const handler = () => val !== localStorage.getItem(key) && setVal(localStorage.getItem(key));
    window.addEventListener("storage", handler);
    return window.removeEventListener("storage", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVal(localStorage.getItem(key));
  }, [key]);

  return val;
}


export const normalizeStrings = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].toUpperCase();
      obj[key] = obj[key].toLowerCase();
    }
  }
  return obj
}

export function generateKeyId(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/// Obtener el nombre del navegador y la version
export const getBrowserInfo = () => {
  var ua = navigator.userAgent, tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};

// Obtiene el tipo de dispositivo se esta utlizando
export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 1;
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 1;
  }
  return 2;
};

export const formatDate = (date, format) => {
  const map = {
    mm: date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
    dd: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
    yyyy: date.getFullYear()
  }

  return format.replace(/mm|dd|yyyy/gi, matched => map[matched])
}

export const addDaysNow = (days) => {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}


export const concatenateTimeIntoDate = (date, hours, minutes) => {
  let minutesString = minutes > 9 ? `${minutes}` : `0${minutes}`;;
  let hoursString = hours > 9 ? `${hours}` : `0${hours}`;
  return `${date}T${hoursString}:${minutesString}`
}

export const convertDateinFormatValid = (dateCloud) => {
  const newDate = new Date(dateCloud);
  const date = formatDate(newDate, "yyyy-mm-dd");
  const hours = newDate.getHours();
  const minutes = `${newDate.getMinutes()}`
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
  return `${date}T${hoursString}:${minutesString}`
}

export const makeId = (length) => {
  var result = []
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

export const removeAndAddWordsInProperties = (data, keyReplace, replaceWord, isAdd) => {
  let dataResponse = [];
  for (const item of data) {
    let dataChildrenResponse = [];
    if (item.children !== undefined && item.children.length > 0) {
      dataChildrenResponse = removeAndAddWordsInProperties(item.children, keyReplace, replaceWord);
    }

    if (isAdd) {
      if (dataChildrenResponse.length > 0) {
        dataResponse.push({
          ...item,
          [keyReplace]: item[keyReplace] !== undefined ? `${item[keyReplace]}px` : "24px",
          children: dataChildrenResponse
        })
      } else {
        dataResponse.push({
          ...item,
          [keyReplace]: item[keyReplace] !== undefined ? `${item[keyReplace]}px` : "24px"
        })
      }
    } else {
      if (dataChildrenResponse.length > 0) {
        dataResponse.push({
          ...item,
          [keyReplace]: item[keyReplace] !== undefined ? item[keyReplace].replace(replaceWord, "") : 20,
          children: dataChildrenResponse
        })
      } else {
        dataResponse.push({
          ...item,
          [keyReplace]: item[keyReplace] !== undefined ? item[keyReplace].replace(replaceWord, "") : 20
        })
      }
    }
  }

  return dataResponse;
}

export const convertPrivilegesToList = (data) => {
  let dataResponse = [];
  for (const item of data) {
    let dataChildrenResponse = [];
    if (item.children !== undefined && item.children.length > 0) {
      dataChildrenResponse = convertPrivilegesToList(item.children);
    }

    if (dataChildrenResponse.length > 0) {
      dataResponse.push({
        ...item,
        privilege: typeof item.privilege === "string" ? item.privilege.split(",") : item.privilege,
        children: dataChildrenResponse
      })
    } else {
      dataResponse.push({
        ...item,
        privilege: typeof item.privilege === "string" ? item.privilege.split(",") : item.privilege,
      })
    }
  }

  return dataResponse;
}

/**
   * This mehtod disable all children when father is disabled
   * @param {*} child to disable --> isActivate false
   * @param {*} father current father may be null only in root position
   */
export const updateActivateState = (child, father) => {
  for (let i = 0; i < child.length; i++) {
    const element = child[i];
    if (father != null && father.isActivate === false) {
      element.isActivate = false;
    }
    if (element.children.length > 0) {
      updateActivateState(element.children, element);
    }

  }
}
