import React from "react";

/**
 * This component capture a error
 * Prevents the propagation of error in all aplication
 * Show a message instead of white page
 */
class ErrorBoundaryCapture extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false , messageError: ""};
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: true , messageError: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // También puedes registrar el error en un servicio de reporte de errores
    //logErrorToMyService(error, errorInfo);
    console.log(`A error has ocurred !!!!! ${error.message} `);
  }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz de repuesto
      return (
        <div>
          <h1>Something error has occurred. 😅</h1>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundaryCapture;
