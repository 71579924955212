import {
  Box,
  Card,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Create } from "@material-ui/icons";
import React from "react";
import AlertConfirm from "../alertConfirm.component";
import IconEspecialButton from "../ButtonForm/IconEspecialButton.component";
import { ArrowIcon } from "../IconsLibrary/ArrowIcon";
const useStyles = makeStyles((theme) => ({
  card: {
    //  margin: "0px 15px",
  },
  containerA: {
    paddingRight: "48px",
  },
  containerB: {
    paddingRight: "40px",
  },
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.secondary.main,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
  subtitle: {
    fontWeight: 600,
  },
  spacing: 0,
}));

const FormLayout = ({
  subheader,
  handleSubmit,
  handleIconClick,
  confirmInactivate,
  setConfirmInactivate,
  handleAccept,
  handleCancel,
  children,
  isEditing,
  enabledForm,
  setEnabledForm,
  handleEditButton,
  isPrivilege,
  isMenuSettings,
}) => {
  const classes = useStyles();

  const setOpen = (open) => {
    setConfirmInactivate({
      ...confirmInactivate,
      open: open,
    });
  };

  return (
    <div>
      <Card className={`${classes.card} 'NewRole-Card'`}>
        <Grid
          container
          direction="row"
          className={isPrivilege ? classes.containerB : classes.containerA}
        >
          <Grid
            container
            item
            lg={9}
            alignItems="center"
            style={{ paddingLeft: 20 }}
          >
            {
              isMenuSettings !== true ? (
                <Tooltip
                  id="button-report"
                  title="Atrás"
                  style={{ float: "left", margin: "17px 2px 17px 17px" }}
                >
                  <IconButton area-label="Report" onClick={handleIconClick}>
                    <ArrowIcon className={classes.previousArrow} />
                  </IconButton>
                </Tooltip>) : (<></>)
            }
            <Typography
              color="primary"
              className={classes.subtitle}
              variant="h6"
            >
              {subheader}
            </Typography>
          </Grid>
          <Grid container item lg={3} alignItems="center" justifyContent="flex-end">
            {isEditing ? (
              <IconEspecialButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={() => {
                  if (typeof handleEditButton === "function") {
                    handleEditButton();
                  } else {
                    setEnabledForm(!enabledForm);
                  }
                }}
                startIcon={<Create />}
              >
                {enabledForm ? "Cancelar edición" : "Editar"}
              </IconEspecialButton>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <form id="form-save" onSubmit={handleSubmit} autoComplete="off">
          <Box mx={5}>{children}</Box>
        </form>
      </Card>
      {!!confirmInactivate
        ? confirmInactivate.open && (
            <AlertConfirm
              isOpen={confirmInactivate.open}
              setOpen={setOpen}
              modalMessage={confirmInactivate.message}
              handleAccept={
                !!confirmInactivate.handleAccept
                  ? confirmInactivate.handleAccept
                  : handleAccept
              }
              customFunction={handleAccept}
              showBtnAccept={confirmInactivate.showBtnAccept}
              showBtnCancel={confirmInactivate.showBtnCancel}
              showBtnClose={false}
              textButtonCancel="No"
              textButtonAccept={confirmInactivate.textButtonAccept}
              onClose={() => {
                if (typeof handleCancel === "function") {
                  handleCancel();
                } else {
                  setConfirmInactivate({
                    open: false,
                    checked: false,
                  });
                }
              }}
            />
          )
        : null}
    </div>
  );
};

export default FormLayout;