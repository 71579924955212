import {
  APPLICATION_CREATED,
  APPLICATION_ERROR,
  APPLICATION_MODIFIED,
  APPLICATION_ACTIVATED,
  GET_ALL_APPLICATIONS,
  GET_ALL_APPLICATIONS_MENU,
  GET_ALL_APPLICATIONS_NOTIFICATION,
  GET_VERSION_APP,
  GET_MAINTENANCE_WINDOW,
  SET_PARAMETER_APP,
} from '../actions/types';

const initialState = {
  getApplicationsResponse: [],
  inactivateResponse: {},
  getApplicationChangedResponse: {},
  applicationCreated: {},
  applicationError: {},
  applicationModified: {},
  applicationActivated: {},
  getApplicationsMenuResponse: {},
  getApplicationsNotificationResponse: {},
  getVersionApp: {},
  getParmeterConfig: {},
}

export default function applicationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_APPLICATIONS:
      return {
        ...state,
        getApplicationsResponse: action.payload
      };
    case APPLICATION_CREATED:
      return {
        ...state,
        applicationCreated: action.payload
      };
    case APPLICATION_MODIFIED:
      return {
        ...state,
        applicationModified: action.payload
      };
    case APPLICATION_ACTIVATED:
      return {
        ...state,
        applicationActivated: action.payload
      };
    case APPLICATION_ERROR:
      return {
        ...state,
        applicationError: action.payload
      };
    case GET_ALL_APPLICATIONS_MENU:
      return {
        ...state,
        getApplicationsMenuResponse: action.payload,
      };
    case GET_ALL_APPLICATIONS_NOTIFICATION:
      return {
        ...state,
        getApplicationsNotificationResponse: action.payload,
      };
    case GET_VERSION_APP:
      return {
        ...state,
        getVersionApp: action.payload,
      };
    case GET_MAINTENANCE_WINDOW:
      return {
        ...state,
        getMaintenanceWindow: action.payload,
      };
    case SET_PARAMETER_APP:
      return {
        ...state,
        parameterConfig: action.payload
      };
    default:
      return state;
  }
}