import { ApplicationsMenuIcon } from "../components/IconsLibrary/ApplicationsMenuIcon";
import { LateralMenuIcon } from "../components/IconsLibrary/LateralMenuIcon";
import { TopMenuIcon } from "../components/IconsLibrary/TopMenuIcon";
import { UserMenuIcon } from "../components/IconsLibrary/UserMenuIcon";

export const actions = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  CREATED: "created",
  REMOVE: "remove",
  PARTIAL_UPDATED: "PARTIAL_UPDATED",
  NOT_CHANGES: "NOT_CHANGES"
};

export const menuTypes = [
    {
        value: 1,
        name: "Menú Lateral",
        icon: LateralMenuIcon,
    },
    {
        value: 2,
        name: "Menú Superior",
        icon: TopMenuIcon,
    },
    {
        value: 3,
        name: "Menú Usuario",
        icon: UserMenuIcon,
    },
    {
        value: 4,
        name: "Menú Suite Aplicaciones",
        icon: ApplicationsMenuIcon,
    },
];
export const messagesResponseRoles = {
  createRole: "El rol fue creado exitosamente",
  updateRoleData: "Los datos del rol han sido actualizados",
  updateRoleApps: "!El rol fue actualizado con exito¡",
  noChanges: "No hay cambios para guardar",
};

export const states = {
  Activo: 0,
  Inactivo: 1,
  Eliminado: 2,
  Bloqueado: 3,
  Pendiente: 4,
  Ejecutado: 5
}

export const typeFieldNotification = {
  TIME: "time",
  CHECK: "check",
  DATE: "date",
  TEXT: "text"
}

export const TypeAlertNotification = {
  CREATED: "CREATED",
  SAVED_CHANGES: "SAVED_CHANGES",
  MODIFIED: "MODIFIED",
  CHANGE_APPLICATION: "CHANGE_APPLICATION"
}
