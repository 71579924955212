import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { getCustomers } from "../../actions/user.action";
import AlertConfirm from "../../components/alertConfirm.component";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CustomTable from "../../components/Table/CustomTable.component";
import SwitchForm from "../../components/Switch/switchForm.component";
import { isEmpty } from "../../utils/proprietaryHooks";
import { SET_CUSTOMERS_ERROR } from "../../actions/types";

/**
 * UserCustomer Component ( view customer from a user )
 *
 * @export Class Component
 * @class UserCustomer
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  card: {
    
  },
  filter: {
    // paddingLeft: "23px",
  },
  searchTextField: {
    width: "468px",
    maxHeight: "25px",
    paddingLeft: "23px",
  },
  tableCellHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  tableCellBody: { padding: "12px" },
}));

const UserCustomer = forwardRef((props, ref) => {
  const classes = useStyles();
  const { customers, setCustomers } = props;
  const {
    userId,
    setErrorsCustomer,
    getCustomers,
    getCustomersResponse,
    setEditedCustomers,
    setLoading,
    isEnabled,
    customersErrors,
  } = props;

  const [confirmPrincipal, setConfirmPrincipal] = useState(false);
  const [checked, setChecked] = useState({
    index: "",
    name: "",
    checked: false,
    id: "",
  });
  const [option, setOption] = useState(null);
  const dispatch = useDispatch();
  const columns = [
    {
      id: "name",
      label: "Empresa",
      minWidth: 280,
      align: "left",
      haveDropDown: true,
      key: " ",
      wordLength: 40,
    },
    {
      id: "principal",
      label: "Empresa principal",
      minWidth: 90,
      align: "center",
      haveDropDown: true,
      withIcon: false,
      tooltipMessage: "principal",
      format: (value) => value,
      children: (value, row) => {
        return switchComponent(value, row, "principal");
      },
    },
    {
      id: "assigned",
      label: "Asignado",
      minWidth: 20,
      format: (value) => value,
      haveDropDown: true,
      tooltipMessage: "Asignado",
      children: (value, row) => {
        return switchComponent(value, row, "assigned");
      },
    },
  ];

  const switchComponent = (value, row, id) => {
    return (
      <Grid container direction="row" alignItems={"center"} justifyContent={"center"}>
        <div>
          <SwitchForm
            checked={value}
            name={id}
            disabled={!isEnabled}
            onChange={(e) => {
              handleConfirmCustomer(e, row.id, id);
            }}
          />
        </div>
      </Grid>
    );
  };

  const handleConfirmCustomer = (event, id, eventName) => {
    setChecked({
      name: eventName,
      checked: event.target.checked,
      id,
    });
    if (event.target.checked === true && eventName === "principal") {
      //Muestra el confirmar
      const principalChecked = customers.findIndex(
        (customer) => customer.principal === true
      );
      if (principalChecked !== -1) {
        setErrorsCustomer(false);
        setConfirmPrincipal(true);
      } else {
        handleChangeCheckedCustomer(id, eventName);
      }
    } else {
      handleChangeCheckedCustomer(id, eventName);
    }
  };

  const handleChangeCheckedCustomer = (eventId, eventName) => {
    let name,
      id = "";
    let temporalCustomers = customers;

    if (eventName !== undefined) {
      name = eventName;
      id = eventId;
    } else {
      name = checked.name;
      id = checked.id;
    }
    if (name === "assigned") {
      temporalCustomers = temporalCustomers.map((item) =>
        item.id === id && !item.principal
          ? { ...item, assigned: !item.assigned }
          : item
      );
    } else if (name === "principal") {
      temporalCustomers = temporalCustomers.map((item) => {
        if (item.principal) {
          return {
            ...item,
            principal: false,
          };
        } else if (item.id === id) {
          setErrorsCustomer(false);
          return {
            ...item,
            principal: true,
            assigned: false,
          };
        } else {
          return item;
        }
      });
    }
    setCustomers(temporalCustomers);
    setConfirmPrincipal(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortBy = [
    {
      prop: "principal",
      direction: -1,
    },
    {
      prop: "assigned",
      direction: -1,
    },
  ];

  const compareCustomers = useCallback(
    (a, b) => {
      let i = 0,
        result = 0;
      while (i < sortBy.length && result === 0) {
        result =
          sortBy[i].direction *
          (a[sortBy[i].prop] < b[sortBy[i].prop]
            ? -1
            : a[sortBy[i].prop] > b[sortBy[i].prop]
            ? 1
            : 0);
        i++;
      }
      return result;
    },
    [sortBy]
  );

  const sortData = useCallback(
    (data) => {
      data = data.sort((a, b) =>
        a.name === b.name ? 0 : a.name > b.name ? 1 : -1
      );
      data = data.sort((a, b) => compareCustomers(a, b));
      return data;
    },
    [compareCustomers]
  );

  useEffect(() => {
    if (customers.length === 0) {
      getCustomers(userId);
    }
  }, [customers, getCustomers, userId]);

  useEffect(() => {
    if (!isEmpty(getCustomersResponse) && !customers.length) {
      setCustomers(sortData(getCustomersResponse));
      setEditedCustomers(getCustomersResponse);
      if (!!getCustomersResponse.length) {
        setLoading(false);
      }
    }
  }, [
    customers,
    getCustomersResponse,
    setCustomers,
    setEditedCustomers,
    setLoading,
    sortData,
  ]);

  useEffect(() => {
    if (!isEmpty(customersErrors)) {
      setErrorsCustomer(customersErrors.result.information);
      setLoading(false);
      dispatch({
        type: SET_CUSTOMERS_ERROR,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersErrors, setErrorsCustomer]);

  const getButtons = () => {
    let button = {
      justify: "space-between",
      searchBar: {
        cellSize: {
          lg: 7,
          md: 2,
          sm: 2,
        },
      },
    };
    if (!!userId) {
      button = {
        ...button,
        menuOrder: {
          cellSize: {
            lg: 6,
            md: 2,
            sm: 2,
          },
          justify: "center",
          options: [
            {
              label: "Activos",
              columns: [
                {
                  prop: "principal",
                  direction: -1,
                },
                {
                  prop: "assigned",
                  direction: -1,
                },
              ],
            },
            {
              label: "Inactivos",
              columns: [
                {
                  prop: "principal",
                  direction: 1,
                },
                {
                  prop: "assigned",
                  direction: 1,
                },
              ],
            },
            {
              label: "Modificados recientemente",
              columns: [
                {
                  prop: "modifiedOn",
                  direction: 1,
                  format: (date) => {
                    return new Date(date).getTime();
                  },
                },
              ],
            },
          ],
        },
      };
    }

    return button;
  };

  return (
    <Grid item lg={12} md={12} sm={12}>
      <Card className={classes.card}>
        <CustomTable
          columns={columns}
          data={customers}
          mainParam={"name"}
          havePagination={true}
          buttons={getButtons()}
          option={option}
          setOption={setOption}
          globalDisabled={!isEnabled}
        />
      </Card>

      {confirmPrincipal && (
        <AlertConfirm
          isOpen={confirmPrincipal}
          modalMessage={`¿Desea cambiar la empresa principal?`}
          handleAccept={() => {
            handleChangeCheckedCustomer();
          }}
          showBtnAccept={true}
          showBtnCancel={true}
          onCancel={true}
          onClose={() => setConfirmPrincipal(false)}
        />
      )}
    </Grid>
  );
});

UserCustomer.propTypes = {
  getCustomers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getCustomersResponse: state.userReducer.getCustomersResponse,
  customersErrors: state.userReducer.customersErrors,
});

const mapDispatchToProps = {
  getCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UserCustomer);
