
import {
  SET_CURRENT_USER,
  GET_MENU_LIST,
  GET_MENU_APP_LIST,
  GET_MENU_CHANGE,
  GET_MENU_IMAGE_CHANGE,
  GET_MENU_ERROR_CHANGE,
  GET_MENU_BY_USER_LIST_RESPONSE,
  GET_MENU_TOP_RESPONSE,
  GET_MENU_USER_RESPONSE,
  GET_MENU_LEFT_RESPONSE,
  GET_SUITE_MENU_RESPONSE,
  GET_UPLOAD_IMAGE_MENU,
} from "../actions/types";

const initialState = {
  getMenuResponse: [],
  getMenuListResponse: [],
  getMenuByUserListResponse: [],
  getMenuByAppResponse: [],
  getMenuChangeResponse: {},
  getMenuImageResponse: {},
  getMenuErrorResponse: {},
  getLeftMenuResponse: [],
  getTopMenuResponse: [],
  getUserMenuResponse: [],
  getSuiteMenuResponse: [],
  getUploadImageMenu: {},
};

/**
 * MenuReducer (set user Menu or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function menuReducer (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        getMenuResponse: action.payload,
      };
    case GET_MENU_LIST:
      return {
        ...state,
        getMenuListResponse: action.payload,
      };
    case GET_MENU_BY_USER_LIST_RESPONSE:
      return {
        ...state,
        getMenuByUserListResponse: action.payload,
      };
    case GET_MENU_APP_LIST:
      return {
        ...state,
        getMenuByAppResponse: action.payload,
      };
    case GET_MENU_CHANGE:
      return {
        ...state,
        getMenuChangeResponse: action.payload,
      };
    case GET_MENU_IMAGE_CHANGE:
      return {
        ...state,
        getMenuImageResponse: action.payload,
      };
    case GET_MENU_ERROR_CHANGE:
      return {
        ...state,
        getMenuErrorResponse: action.payload,
      };
    case GET_MENU_LEFT_RESPONSE:
      return {
        ...state,
        getLeftMenuResponse: action.payload,
      };
    case GET_MENU_TOP_RESPONSE:
      return {
        ...state,
        getTopMenuResponse: action.payload,
      };
    case GET_MENU_USER_RESPONSE:
      return {
        ...state,
        getUserMenuResponse: action.payload,
      };
    case GET_SUITE_MENU_RESPONSE:
      return {
        ...state,
        getSuiteMenuResponse: action.payload,
      };
    case GET_UPLOAD_IMAGE_MENU:
      return {
        ...state,
        getUploadImageMenu: action.payload,
      };
    default:
      return state;
  }
}
