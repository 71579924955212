import React from 'react'

const TrashIcon = ({ style = {}, active,
    width = "100%", className = "",
    viewBox = "0 0 15.629 19", ...props }) => {
    const fill = active ? "#009bdd" : "#6d6e71";
    return (
        <svg id="eliminar" xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={width}
            viewBox={viewBox}
            style={style}
            className={`svg-icon ${className || ""}`}
            {...props}
        >
            <g id="Grupo_2912" data-name="Grupo 2912">
                <path id="trash-alt-solid-2" d="M3407.053,328.283h-4.185l-.329-.73a.833.833,0,0,0-.749-.519H3397.8a.821.821,0,0,0-.746.519l-.329.73h-4.185a.593.593,0,0,0-.559.624v1.249a.594.594,0,0,0,.559.626h14.509a.593.593,0,0,0,.559-.626v-1.249A.593.593,0,0,0,3407.053,328.283Z" transform="translate(-3391.983 -327.034)" fill={fill} />
                <path id="Sustracción_15" data-name="Sustracción 15" d="M11.057,13.7H1.5A1.5,1.5,0,0,1,0,12.2V0H12.557V12.2A1.5,1.5,0,0,1,11.057,13.7ZM9.7,2.283a.571.571,0,0,0-.571.571V9.7a.571.571,0,0,0,1.141,0V2.854A.571.571,0,0,0,9.7,2.283Zm-3.424,0a.571.571,0,0,0-.571.571V9.7a.571.571,0,0,0,1.141,0V2.854A.571.571,0,0,0,6.278,2.283Zm-3.424,0a.571.571,0,0,0-.571.571V9.7a.571.571,0,0,0,1.141,0V2.854A.571.571,0,0,0,2.854,2.283Z" transform="translate(1.536 5.301)" fill={fill} />
            </g>
        </svg>
    )
}

export default TrashIcon
