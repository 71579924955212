import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
} from "@material-ui/core";

const CustomModal = ({
  open,
  handleClose,
  body,
  header,
  actions,
  width,
  handleSubmit,
  disableClose,
}) => {
  const classes = useStyles({ width });

  return (
    <Modal
      //disableBackdropClick --deprecated
      className={classes.root}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Card className={classes.paper}>
        <form onSubmit={handleSubmit}>
          {disableClose ? null : (
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction="row-reverse"
            >
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <IconButton onClick={handleClose}>
                  <span className={`material-icons ${classes.closeIcon}`}>
                    clear
                  </span>
                </IconButton>
              </Grid>
            </Grid>
          )}
          {header ? <CardHeader title={header} /> : null}
          <CardContent className={classes.content}>{body}</CardContent>
          <CardActions className={classes.content}>{actions}</CardActions>
        </form>
      </Card>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", [theme.breakpoints.up("xl")]: { alignItems: "center" }, justifyContent: "center", overflow: 'scroll' },
  paper: {
    position: "absolute",
    background: `${theme.palette.background.paper} 0% 0% no-repeat padding-box`,
    boxShadow: "0px 6px 6px #00000080",
    padding: theme.spacing(0, 0, 0, 0),
    width: ({ width }) => (width ? width : "400px"),
    minWidth: 400,
    margin: "0 auto",
    borderRadius: 12,
  },
  content: {
    margin: theme.spacing(0, 4, 2, 4),
  },
  closeIcon: {
    color: theme.palette.primary.light,
    fontSize: "15px",
  },
}));

export default CustomModal;
