import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  useTheme,
  Grid,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Box from '@material-ui/core/Box';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ErrorBoxUko from "../../../components/errorBoxUko.component";
import AuthenticationLayoutUko from "../../../components/AuthenticationLayoutUkoBeMyself/AuthenticationLayoutUkoBeMyself";
import CustomInput from "../../../components/AuthenticationLayoutUkoBeMyself/CustomInputBeMyself";
import PrimaryButtonUkoBeMyself from "../../../components/PrimaryButtonUkoBeMyself.component";
import { makeStyles } from "@material-ui/core/styles";
import { string } from "prop-types";
import { resetPassword, clearAuthenticationResponse, getUserForResetPassword } from "../../../actions/auth.action";
import { getVersionAppAction } from "../../../actions/applications.action";
import { isEmpty } from "../../../utils/proprietaryHooks";

const ResetPasswordUko = (props) => {
  const {
    resetPassword,
    appName,
    getAuthenticationResponse,
    getVersionAppAction,
    getUserForResetPassword,
    getVersionApp,
    userList,
    tenant,
  } = props;

  const [errorsValidation, setErrorsValidation] = useState({});
  const [validateCallBack, setValidateCallBack] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [versionMessage, setVersionMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState();
  const [t, i18n] = useTranslation("global");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .max(100)
      .required(t("reset-password.invalid-email.required"))
      .email(t("reset-password.invalid-email.text"))
      .matches(/^[\w][\w-.+]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,3}$/,
        t("reset-password.invalid-email.text"))
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },

    resolver: yupResolver(validationSchema),
  });
  const watchEmail = watch('email');
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  /**
   * Reset password for an a user
   * @param {*} userToResetPassWord 
   */
  const resetPasswordOnceTime = (userToResetPassWord) => {
    let userData = {
      user: userToResetPassWord,
      appname: appName,
      isInvalidLinkScreen: false,
      isByUser: true,
    };
    setLoading(true);
    resetPassword(userData, history, '', tenant);
  };

  const resetUserPassword = async (data) => {
    setCurrentEmail(data.email);
    const userData = {
      user: data.email,
      appname: appName,
    };
    setLoading(true);
    getUserForResetPassword(userData, tenant);
  };

  useEffect(() => {
    setLoading(false);
    const actionResponse = userList?.data
    if (actionResponse?.length > 0 && (typeof actionResponse) !== 'string') {
      if (actionResponse.length > 1) {
        history.push({
          pathname: "/selectaccounts",
        });
      } else {
        resetPasswordOnceTime(actionResponse[0].userName);
      }
    } else if (actionResponse !== undefined) {
      showWrongResponse(actionResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  /**
   * funcion expresion validación de correo
   */
  const validEmail = new RegExp(/^[\w][\w.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,3}$/);

  /**
   * Show Error component into <Box/>
   * @param {*} errorMessage message to show
   * @returns 
   */
  const customErrorElement = (errorMessage) => {
    return (
      <Box>
        <ErrorBoxUko value={errorMessage} >
        </ErrorBoxUko>
      </Box>
    );
  }

  /**
   * 
   * @returns retorna error presentado en validación
   */
  const getErrors = () => {
    if (!errors.lenght > 0) {
      let errorMessage = string.empy;
      if (errors.email?.message !== "") {
        errorMessage = errors.email?.message;
      }
      if (errorMessage && watchEmail !== "") {
        return customErrorElement(errorMessage);
      }
    }
    if (errorsValidation?.email && validateCallBack) {
      const messageValue = errorsValidation?.email?.message;
      return customErrorElement(messageValue);
    }
  };

  const handleOnValidate = (e) => {
    setValidateCallBack(false);
    clearAuthenticationResponse();
    const email = e.target.value;

    if (!validEmail.test(email)) {
      setDisabledButton(true);
    }
    else {
      setDisabledButton(false);
    }
  }

  useEffect(() => {
    clearAuthenticationResponse();
    setErrorsValidation({});
    getVersionAppAction(appName, tenant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!!getAuthenticationResponse) {
      if (getAuthenticationResponse.responseCode === 200) {
        setValidateCallBack(false);
        setDisabledButton(false);
      } else {
        showWrongResponse();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthenticationResponse]);

  /**
   * Actions when user petition from back is unsuccessful
   * @param {*} wrongResponse used when error is not call from redux
   */
  const showWrongResponse = (wrongResponse) => {
    setLoading(false);
    setDisabledButton(true);
    setValidateCallBack(true);
    setErrorsValidation({
      email: {
        message: wrongResponse !== null && wrongResponse !== undefined ?
          wrongResponse : getAuthenticationResponse.message,
        type: "invalid-user"
      }
    });
  }

  useEffect(() => {
    if (!isEmpty(getVersionApp)) {
      setVersionMessage(getVersionApp.version);
    }
  }, [getVersionApp]);

  return (
    <AuthenticationLayoutUko title={t("reset-password.header.label-reset-password")} logoApp={theme.urlLogo.urlRememberPasswordlogo} logoSignIn={theme.urlLogo.urlSignIn} >
      <form id="resetPasswordUko-validation" autoComplete="off">
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item xs={12} className={classes.gridItem}>
            <Box>
              <Typography className={classes.sendEmailMessage}>
                {t("reset-password.description-reset-password")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <CustomInput
              label={t("reset-password.input.email")}
              name="email"
              control={control}
              error={errors?.email ? errors : errorsValidation}
              styleFromHelperText={{ style: { display: 'none' } }}
              classes={classes.typeInput}
              autoFocus={true}
              onValidate={handleOnValidate}
              required
            />
          </Grid>
          {getErrors()}
          <PrimaryButtonUkoBeMyself
            txtBtn={t("reset-password.button")}
            disabled={disabledButton}
            loading={loading}
            onClick={handleSubmit(resetUserPassword)}
            isAnotherButtonDisabled={true}
          />
        </Grid>
      </form>
      <div classes={classes.divSpace} ></div>
      <Grid item xs={12} className={`${classes.gridItem}`}>
        <Typography
          className={classes.typographyGoBack}
        >
          <Link
            to="/login"
            className={classes.linkGoBack}
          >
            {t("reset-password.link-login")}
          </Link>
        </Typography>
      </Grid>
      <Box className={classes.version}>
        <p>{versionMessage}</p>
      </Box>
    </AuthenticationLayoutUko>
  );
};

ResetPasswordUko.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
  getAuthenticationResponse: state.authReducer.getAuthenticationResponse,
  getVersionApp: state.applicationsReducer.getVersionApp,
  userList: state.authReducer.userList,
});

const mapDispatchToProps = {
  resetPassword,
  clearAuthenticationResponse,
  getVersionAppAction,
  getUserForResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordUko);

const useStyles = makeStyles((theme) => ({
  typeInput: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '8px',
      width: '486px',
      height: '56px',
      backgroundColor: theme.InputUko.backgroundColor,
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.InputUko.colorInputLetter,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      borderColor: theme.InputUko.borderInput,
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      color: theme.InputUko.colorLabel,
      fontSize: '17px',
      marginTop: '7px',
    },
    "& .MuiInputLabel-root.Mui-focused": {
      fontWeight: 600,
      color: theme.InputUko.colorLabelFocus,
    },
    "& .MuiSvgIcon-root": {
      color: theme.InputUko.colorIcon,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.dark,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.error.dark,
    },
    "& .MuiFormLabel-asterisk.Mui-error": {
      color: theme.palette.error.dark,
    },
    "& .MuiInputLabel-shrink": {
      margin: "0 auto",
      position: "absolute",
      top: "1px",
    },
  },
  gridContainer: {
    gap: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  divSpace: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between',
    marginTop: '24px',
    marginBottom: '24px'
  },
  sendEmailMessage: {
    color: theme.typography.colorMessageUko,
    fontSize: '15px',
    fontWeight: 500,
  },
  typographyGoBack: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1.6,
    color: theme.linkUko.colorLink,
    paddingLeft: '8px',
    paddingRight: '8px',
    marginTop: '15px'
  },
  linkGoBack: {
    textDecoration: "underline",
    color: theme.linkUko.colorLink,
    marginBottom: '2px',
    fontSize: '15px',
    fontWeight: 700
  },
  version: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.versionUko.colorVersionLabel,
    position: 'absolute',
    bottom: '15px',
    left: '0',
    right: '0',
    textAlign: 'center',
  }
}));

