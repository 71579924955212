import { Box, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: "none",
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: theme.palette.common.black,
        borderBottom: `1.4px solid ${theme.palette.info.light}`,
        "&$selected": {
            color: theme.palette.secondary.main,
        }
    },
    selected: {
        color: theme.palette.secondary.main,
    }, tabsStyle: {
        borderBottom: `1px ${theme.palette.secondary.dark}`,
    },
    indicator: {
        backgroundColor: theme.palette.secondary.main,
    },
    tabsItem: {
        fontSize: '1rem',
        textTransform: "capitalize",
        padding: "21px",
        marginTop: "0px",
        "&:hover": {
            color:theme.palette.secondary.main,
            opacity: 1,
        },
        "&:focus": {
            color: theme.palette.secondary.main,
        }
    },
}))


const TabComponent = ({ children, tab, handleChangeTab, aria }) => {
    const classes = useStyles();
    return (
        <>

            <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label={aria}
                classes={{
                    indicator: classes.indicator,
                    root: classes.root,
                }}
                p={2}
            >
                {children.map((item, index) => (
                    <Tab
                    key={`${item.label}-${index}`}
                        className={classes.tabsItem}
                        classes={{
                            selected: classes.selected,
                        }}
                        label={item.label}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>
            {
                children.map((item, index) => (
                    <TabPanel value={tab} index={index} key={`tabComponent-${index}`}>
                        <Grid  container  justifyContent="flex-start" >
                            {item.tabContent}
                        </Grid>                       
                    </TabPanel>
                ))
            }
        </>

    )
}

TabComponent.propTypes ={
    children: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        tabContent: PropTypes.any
    })),
    tab: PropTypes.number,
    handleChangeTab: PropTypes.func,
    aria: PropTypes.string

}



function TabPanel(props) {
    const { children, value, index, inputRef, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={1}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default TabComponent
