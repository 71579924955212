import { alpha, makeStyles, TextField } from '@material-ui/core';
import React from 'react'
import { Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    root: {
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 10,
        //height: '43px',
        backgroundColor: "#FFFFFF",
        boxShadow: `0px 1px 1px #00000026`,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:hover": {
            backgroundColor: "#fff",
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        "&$error": {
            borderColor: theme.palette.error.main,
        },
        "& .MuiInputBase-input.Mui-error": {
            borderColor: theme.palette.error.main,
        },
        "& .MuiInputBase-input.Mui-disabled": {
            backgroundColor: "#fff",
        },
        "&.MuiFilledInput-root.Mui-disabled": {
            backgroundColor: "#fff !important",
        },
    },
    focused: {
        fontSize: 14,
    },
    error: {},
    inputHiddenLabel: {
        marginTop: "1px",
        color: "#b2b2b2",
    },
}));

const ControlledInputRoundedForm = ({ inputProps, inputRef, stateShrink, setStateShrink, adornment, control, pattern, ...props }) => {
    const classes = useStyles();
    const regexPattern = new RegExp(pattern);

    const handleSpecialCharacters = (text) => {
        if (pattern === undefined) {
            validateShrink(text);
            return text;
        }
        let value = text.replace(regexPattern, "");
        validateShrink(value);
        return value;
    };

    const validateShrink = (text) => {
        if (text.length === 0) {
            if (typeof setStateShrink === "function") {
                setStateShrink((state) => ({ ...state, [`${props.name}`]: false }));
            }
        } else {
            if (typeof setStateShrink === "function") {
                setStateShrink((state) => ({ ...state, [`${props.name}`]: true }));
            }
        }
    };
    return (
        <Controller
            control={control}
            name={props.name}
            {...props}
            render={({ onChange, value, ...rest }) => (
                <TextField
                    variant="filled"
                    size="small"
                    margin={"dense"}
                    InputProps={{ classes, disableUnderline: true, inputProps, ...adornment }}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputHiddenLabel,
                            focused: classes.focused,
                        },
                        shrink: !!setStateShrink ? stateShrink : props.shrink,
                    }}
                    inputRef={inputRef}
                    value={value}
                    onChange={(e) => {
                        onChange(handleSpecialCharacters(e.target.value));
                    }}
                    {...rest}
                    {...props}
                />
            )}
        />
    );
};

export default ControlledInputRoundedForm;
