import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AuthenticationLayoutUkoBeMyself from "../../../components/AuthenticationLayoutUkoBeMyself/AuthenticationLayoutUkoBeMyself";
import { Grid,  useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButtonUko from "../../../components/primaryButtonUko.component";
import { isEmpty } from "../../../utils/proprietaryHooks";
import { getVersionAppAction } from "../../../actions/applications.action";
import Box from '@material-ui/core/Box';


const SuccessAlertUko = (props) =>{
    const {
        appName,
        getVersionApp,
        tenant,
    } = props
    const theme = useTheme();
    const classes = useStyles();
    const [versionMessage, setVersionMessage] = useState("");
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        if (appName && appName !== "") {
            getVersionAppAction(appName, tenant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (!isEmpty(getVersionApp)) {
            setVersionMessage(getVersionApp.version);
        }
    }, [getVersionApp])


    return (
        <AuthenticationLayoutUkoBeMyself title={t("success-alert.password-updated")} logoApp={theme.urlLogo.urlSuccessAlertlogo} logoSignIn={theme.urlLogo.urlSignIn}>
            <form id="cambiarcontraseña-validation" autoComplete="off">
                <Grid container direction="column" style={{ marginTop: "40px", gap: "16px" }}>
                    <PrimaryButtonUko
                        txtBtn={t("success-alert.link-sign-in")}
                        href="/Login"
                        loading={false}
                    />
                </Grid>
            </form>
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'space-between', marginTop: '24px', marginBottom: '24px' }} ></div>
            <Box className={classes.version}>
                <p>{versionMessage}</p>
            </Box>
        </AuthenticationLayoutUkoBeMyself>
    );
};

const useStyles = makeStyles((theme) => ({
    version: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme.versionUko.colorVersionLabel,
        position: 'absolute',
        bottom: '15px',
        left: '0',
        right: '0',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          position: 'static',
        },
    },
}));


const mapStateToProps = (state) => ({
    getVersionApp: state.applicationsReducer.getVersionApp,
});
export default connect(mapStateToProps, {
    getVersionAppAction,
})(SuccessAlertUko);