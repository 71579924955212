import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import { formatTimeUnit } from '../utils/proprietaryHooks';
import { DEFAULTRESPONSE, APPLICATION_CREATED, APPLICATION_ERROR, 
  APPLICATION_MODIFIED, APPLICATION_ACTIVATED, 
  GET_ALL_APPLICATIONS, AUTH_IS_FREE_TRIAL_APP,
  GET_ALL_APPLICATIONS_MENU, GET_ALL_APPLICATIONS_NOTIFICATION, 
  GET_VERSION_APP, GET_MAINTENANCE_WINDOW,
  SET_PARAMETER_APP, } from './types';


export const getApplications = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_SECURITY_ENDPOINT}/api/application/list`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const applications = response.data.result.records;
      dispatch({
        type: GET_ALL_APPLICATIONS,
        payload: applications
          .filter((app) => app.name !== "RESETOTP")
          .map((item) => ({
            ...item,
            amountTimeComb: `${item.amountTime} ${formatTimeUnit(
              item.timeUnit
            )}`,
          })),
      });
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: APPLICATION_ERROR,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export const getInfoApplicationIsFreeTrial = (appName, setLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`${API_SECURITY_ENDPOINT}/api/application/isFreeTrial`, { headers: { appName } });
    if (response.data.statusCode === 200 && response.data.result.isFreeTrial) {
      dispatch({
        type: AUTH_IS_FREE_TRIAL_APP,
        payload: { url: response.data.result.urlFreeTrial, code: response.data.result.codeFreeTrial },
      });
    } else {
      setLoading(false);
    }
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
}


export const createApplication = (application, tab, activate) => async (
  dispatch
) => {
  try {
    const action = !!application.id ? "modify" : "create";
    const response = await axios.post(
      `${API_SECURITY_ENDPOINT}/api/application/${action}`,
      application,
      defaultHeaders()
    );
    let status = 0;
    let data = {};
    if (response !== undefined) {
      data = response.data;
      status = response.status;
    }

    if (status === 200 || status === 201) {
      if (!!application.id && !activate) {
        dispatch({
          type: APPLICATION_MODIFIED,
          payload: !!tab ? tab : data,
        });
      } else if (activate) {
        dispatch({
          type: APPLICATION_ACTIVATED,
          payload: !!tab ? tab : data,
        });
      } else {
        dispatch({
          type: APPLICATION_CREATED,
          payload: data,
        });
      }
    } else {
      dispatch({
        type: APPLICATION_ERROR,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: APPLICATION_ERROR,
      payload: error.response.data,
    });
  }
};

export const getAppsForMenu = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_SECURITY_ENDPOINT}/api/application/getApplicationsForAssignMenu`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const applications = response.data.result.records;
      dispatch({
        type: GET_ALL_APPLICATIONS_MENU,
        payload: applications,
      });
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: APPLICATION_ERROR,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export const getAppsForNotification = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_SECURITY_ENDPOINT}/api/application/getApplicationsForAssignNotification`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const applications = response.data.result;
      dispatch({
        type: GET_ALL_APPLICATIONS_NOTIFICATION,
        payload: applications,
      });
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: APPLICATION_ERROR,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export const getVersionAppAction = (appName, tenant = '') => async (dispatch) => {
  try {
    let tenantLanguage = '';
    if (appName === 'bemyself') {
      if (!tenant) {
        tenant = 'bmscol';
      }
      tenantLanguage = localStorage.getItem("bms_lang");
      if (!tenantLanguage) {
        tenantLanguage = "es";
      } else {
        tenantLanguage = JSON.parse(tenantLanguage);
      }
      tenantLanguage = tenantLanguage.toUpperCase();
    }
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      appName: appName,
      tenant: tenant,
      tenantLanguage : tenantLanguage,
    });
    const sessionStorageVersion = sessionStorage.getItem('versionApp');
    if (sessionStorageVersion !== null) {
      dispatch({
        type: GET_VERSION_APP,
        payload: JSON.parse(sessionStorageVersion),
      });
    } else {
      const response = await axios.get(
        `${API_SECURITY_ENDPOINT}/api/application/getVersionApp`,
        myConfig
      );
      if (response.data.statusCode === 200) {
        const versionApp = response.data.result;
        if (sessionStorageVersion === null) {
          sessionStorage.setItem('versionApp', JSON.stringify(versionApp));
        }
        dispatch({
          type: GET_VERSION_APP,
          payload: versionApp,
        });
      }
    }
  } catch (error) {
    console.log('error');
    dispatch({
      type: GET_VERSION_APP,
      payload: "",
    });
  }
};

export const getMaintenanceWindowAction = (appName) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      appName: appName,
    });
    const response = await axios.get(
      `${API_SECURITY_ENDPOINT}/api/application/getMaintenanceWindow`,
      myConfig
    );
    console.log(response);
    if (response.data.statusCode === 200) {
      const getMaintenance = response.data.result;
      dispatch({
        type: GET_MAINTENANCE_WINDOW,
        payload: getMaintenance,
      });
    }
  } catch (error) {
    console.log('error');
  }
};

export const getParmeterConfigAction = (appName, parameterType) => async (dispatch) => {
  const config = defaultHeaders();
  Object.assign(config.headers, {
    appName, parameterType,
  });
  const applicationParameters = await axios.get(`${API_SECURITY_ENDPOINT}/api/application/GetParametersApp`, config);
  try {
    const application = applicationParameters.data.result.records.parameters;
    const result = {};

    application.forEach((parameter) => {
      let value;
      try {
        const parsedValue = JSON.parse(parameter.parameterValue);
        // eslint-disable-next-line valid-typeof
        if (typeof parsedValue.value === parsedValue.type) {
          value = parsedValue.value;
        } else {
          throw new Error('Invalid type');
        }
      } catch (error) {
        Error(`Error parsing value for parameter ${parameter.parameterCode}: ${error}`);
      }

      result[parameter.parameterCode] = value;
    });

    dispatch({
      type: SET_PARAMETER_APP,
      payload: result,
    });
    
  } catch (error) {
    console.log('error');
  }
};