import {
  GET_USERS,
  GET_USER,
  SET_USER_STATE,
  GET_USER_APPLICATIONS,
  GET_CUSTOMERS,
  GET_USER_CHANGED,
  GET_USER_UPDATED,
  SET_USER_ERROR,
  SET_USER_DELETE,
  SET_USER_ACTIVATED,
  GET_USER_DATA,
  SET_CUSTOMERS_ERROR,
  SET_USER_EXISTS_RESPONSE,
  GET_USER_ROLES,
  GET_TYPE_USER,
} from "../actions/types";

const initialState = {
  getUsersResponse: [],
  getUserResponse: {},
  rows: null,
  inactivateResponse: [],
  deleteResponse: {},
  getApplicationsResponse: [],
  getRolesListResponse: [],
  getCustomersResponse: [],
  getUserUpdated: {},
  getUserChangedResponse: {},
  activatedResponse: {},
  userDataResponse: {},
  customersErrors: {},
  getUserExistsresponse: {},
  getTypeUser: {},
};

/**
 * MenuReducer (set user Menu or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function userReducer (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        getUsersResponse: action.payload,
        rows: action.rownums,
      };
    case GET_USER:
      return {
        ...state,
        getUserResponse: action.payload,
      };
    case GET_USER_CHANGED:
      return {
        ...state,
        getUserChangedResponse: action.payload,
      };
    case GET_USER_UPDATED:
      return {
        ...state,
        getUserUpdated: action.payload,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        setUserErrorResponse: action.payload,
      };
    case GET_USER_APPLICATIONS:
      return {
        ...state,
        getApplicationsResponse: action.payload,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        getCustomersResponse: action.payload,
      };
    case GET_USER_ROLES:
      return {
        ...state,
        getRolesListResponse: action.payload,
      };
    case SET_USER_STATE:
      return {
        ...state,
        inactivateResponse: action.payload,
      };
    case SET_USER_DELETE:
      return {
        ...state,
        deleteResponse: action.payload,
      };
    case SET_USER_ACTIVATED:
      return {
        ...state,
        activatedResponse: action.payload,
      };
    case GET_USER_DATA:
      return {
        ...state,
        userDataResponse: action.payload,
      };
    case SET_CUSTOMERS_ERROR:
      return {
        ...state,
        customersErrors: action.payload,
      };
    case SET_USER_EXISTS_RESPONSE:
      return {
        ...state,
        getUserExistsresponse: action.payload,
      };
    case GET_TYPE_USER:
      return {
        ...state,
        getTypeUserResponse: action.payload,
      };

    default:
      return state;
  }
}
