import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import global_es from './components/Translations/es/global.json';
import global_en from './components/Translations/en/global.json';
import global_pt from './components/Translations/pt/global.json';

import { APP_HEADER_NAME, TENANT_HEADER_NAME } from "./config/config";
import { themeColors } from "./utils/themeColors";

var appName = "";
var tenant = "";
var language = "";

var request = new XMLHttpRequest();
request.open("GET", document.location, false);
request.send(null);
appName = request.getResponseHeader(APP_HEADER_NAME);
tenant = request.getResponseHeader(TENANT_HEADER_NAME);

if (appName && tenant) {
  appName = appName + "_" + tenant;
  if (themeColors[appName]) {
    const localStorageVersion = localStorage.getItem('bms_lang');
    if (localStorageVersion) {
      language = JSON.parse(localStorageVersion);
    } else {
      language = `${themeColors[appName]["tenantLanguage"]}`;
    }
    localStorage.setItem('bms_lang', JSON.stringify(language));
  }
}

if (appName && !tenant) {
  const storageVersion = localStorage.getItem('bms_lang');
  if (storageVersion) {
    language = JSON.parse(storageVersion);
  } else {
    language = `${themeColors[appName]["tenantLanguage"]}`;
  }
  localStorage.setItem('bms_lang', JSON.stringify(language));
}

if (!language) {
  localStorage.setItem('bms_lang', JSON.stringify("es"));
}

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: language ? language : 'es',
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    },
    pt: {
      global: global_pt
    },
  },
});


ReactDOM.render(
  //<React.StrictMode>
 
  <I18nextProvider i18n={i18next}>
    <App  />
  </I18nextProvider>,
  //</React.StrictMode>
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
