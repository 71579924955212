import React, { useEffect } from "react";
import { connect } from "react-redux";

import ShowAlert from "../components/alert.component";

/**
 * Login Component ( full view Login component, render a login
 * form and make a post request for user verification  )
 *
 * @export Class Component
 * @class Alert
 * @extends {Component}
 * @returns Redux connect
 */

const Alert = (props) => {

  const { history } = props

  const handleClick = () => {
    const pageName = history.location?.state?.pagename;
    if (pageName !== '') {
      pushToHistory(pageName);
    } else {
      pushToHistory();
    }
  }

  useEffect(() => {
    if ((!history.location?.state) || history.location?.state?.message === null) {
      pushToHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Navigate to the route by changing the history 
   * @param {*} path by default /Login
   */
  const pushToHistory = (path = '/Login') => {
    history.push(path)
  }

  return (
    <ShowAlert
      value={history.location.state?.message}
      onClick={handleClick}
    ></ShowAlert>
  );
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Alert);
