import { Card, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import AlertConfirm from "../alertConfirm.component";
import FullLoader from "../Loader/FullLoader.component";
import PageBackground from "../pageBackground.component";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    padding: "10px 0px 10px 25px",
    // width: "97%",
  },
  externalCard: {
    marginTop: 40,
    marginBottom: 40,
    paddingBottom: 40,
    borderRadius: "10px",
    justifyContent: "center",
    opacity: 1,
    width: "90%",
    minWidth: "90% !important",
    background:
      "transparent linear-gradient(180deg, #FFFFFFCC 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  },
  contentCard: (props) => ({
    margin: "10px 25px",
    borderRadius: "10px",
    background: theme.palette.common.white,
    width: "98%",
    minWidth: "93% !important",
    border: `2px solid ${theme.palette.primary.light}`,
  }),
  card: {
    borderRadius: "10px",
    background: theme.palette.common.white,
  },
  contentPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));
const ViewLayout = ({
  headerTitle,
  children,
  confirmInactivate,
  setConfirmInactivate,
  handleAccept,
  handleConfirmClose,
  isLoading,
}) => {
  const classes = useStyles();
  const handleOpen = () => {
    setConfirmInactivate({
      ...confirmInactivate,
      open: false,
    });
  };

  return (
    <div className={classes.contentPage}>
      <PageBackground></PageBackground>
      <div className={classes.externalCard}>
        <div className={classes.title}>{headerTitle}</div>
        <Grid container justifyContent="center">
          <Grid container item lg={12} md={12} sm={12}>
            <div className={classes.contentCard}>
              <Card
                className={`${classes.card} 'User-Card' ${
                  true ? classes.showCardTrue : classes.showCardFalse
                }`}
              >
                {isLoading ? <FullLoader open={isLoading} /> : null}
                {children}
              </Card>
            </div>
          </Grid>
        </Grid>
        {confirmInactivate.open && (
          <AlertConfirm
            isOpen={confirmInactivate.open}
            setOpen={handleOpen}
            customFunction={confirmInactivate.customFunction}
            modalMessage={confirmInactivate.message}
            handleAccept={
              !!confirmInactivate.handleAccept
                ? confirmInactivate.handleAccept
                : handleAccept
            }
            showBtnAccept={confirmInactivate.showBtnAccept}
            showBtnCancel={confirmInactivate.showBtnCancel}
            showBtnClose={false}
            textButtonAccept={confirmInactivate.textButtonAccept}
            textButtonCancel="No"
            onClose={() => {
              if (handleConfirmClose === undefined) {
                setConfirmInactivate({
                  open: false,
                  checked: false,
                });
              } else {
                handleConfirmClose();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ViewLayout;
