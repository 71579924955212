import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import CustomInput from './CustomInputBeMyself';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme, Typography, Box, Link, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PrimaryButtonUkoBeMyself from '../PrimaryButtonUkoBeMyself.component';
import { SendUserActivationEmail, getUserForResetPassword, resetPassword } from '../../actions/auth.action';
import PropTypes from "prop-types";
import ErrorBoxUko from '../errorBoxUko.component';
import { getVersionAppAction } from "../../actions/applications.action";
import { isEmpty } from "../../utils/proprietaryHooks";


const LayouthInfoUkoBeMyself = (props) => {

    const theme = useTheme();
    const history = useHistory();

    const [isValidEmail, setIsValidEmail] = useState(false);
    const [currentEmail, setCurrentEmail] = useState("");
    const [isLoadinButton, setIsLoadinButton] = useState(false);
    const [validateCallBack, setValidateCallBack] = useState(false);
    const [versionMessage, setVersionMessage] = useState("");
    const [errorsValidation, setErrorsValidation] = useState({});
    const [t, i18n] = useTranslation("global");

    const {
        appName,
        firstImage,
        title,
        secondImage,
        widthOfSecondImage,
        infoText,
        hasInput,
        textBeforeEnd,
        textInTheEnd,
        onClickLink,
        textInLink,
        resetPassword,
        getVersionAppAction,
        showLoading,
        getVersionApp,
        getAuthenticationResponse,
        getUserForResetPassword,
        userList,
        tenant,
    } = props;

    const standarStyles = {
        firstText: {
            fontWeight: "700",
            fontStyle: 'normal',
            fontSize: "28px",
            marginTop: "20px",
            color: theme.palette.secondary.titleText,
            textAlign: "center",
        },
        versionApp: {
            position: 'absolute',
            bottom: '5px',
        }
    }

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .max(100)
            .required(t("layouth-info.input.required"))
            .email(t("layouth-info.input.no-valid"))
            .matches(/^[\w][\w.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,3}$/,
                t("layouth-info.input.no-valid"))
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            email: "",
        },

        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        sendEmailIfIsNecesay();
        if (appName && appName !== "") {
            getVersionAppAction(appName, tenant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isEmpty(getVersionApp)) {
            setVersionMessage(getVersionApp.version);
        }
    }, [getVersionApp]);

    const handleChangeOfEmail = (event) => {
        setValidateCallBack(false);
        const emailFromInput = event.target.value;
        validationSchema.isValid({ email: emailFromInput })
            .then(function (valid) {
                setIsValidEmail(valid);
                setCurrentEmail(emailFromInput);
            });
    }

    const customErrorElement = (errorMessage) => {
        return (
            <Box m={1} pt={0} className={classes.customErrorBox}>
                <ErrorBoxUko value={errorMessage}>
                </ErrorBoxUko>
            </Box>
        );
    }

    const getErrors = () => {
        if (errors.email) {
            if (errors.email.type !== 'required') {
                return customErrorElement(errors.email.message);
            }
        }
        if (errorsValidation?.email && validateCallBack) {
            const messageValue = errorsValidation?.email?.message;
            return customErrorElement(messageValue);
        }
    };

    useEffect(() => {
        if(errorsValidation.email) {
            setIsValidEmail(false);
        }
    }, [errorsValidation]);

    /**
   * Actions when user petition from back is unsuccessful
   * @param {*} wrongResponse used when error is not call from redux
   */
    const showWrongResponse = (wrongResponse) => {
        setIsLoadinButton(false);
        setIsValidEmail(false);
        setValidateCallBack(true);
        setErrorsValidation({
            email: {
                message: wrongResponse !== null && wrongResponse !== undefined ?
                    wrongResponse : getAuthenticationResponse.message,
                type: "invalid-user"
            }
        });
    }

    /**
     * When email is correct and button send is clicked
     */
    const handleResetPassword = async () => {
        setIsLoadinButton(true);
        const userData = {
            user: currentEmail,
            appname: appName,
            isByUser: true,
        }
        getUserForResetPassword(userData, tenant);
    }

    const resetPasswordOnceTime = (userToResetPassWord) => {
        setIsLoadinButton(true);
        const userData = {
            user: userToResetPassWord,
            appname: appName,
            isInvalidLinkScreen: true,
            isByUser: true,
        }
        resetPassword(userData, history, '', tenant);
    }

    useEffect(() => {
        const actionResponse = userList?.data
        if (actionResponse?.length > 0 && (typeof actionResponse) !== 'string') {
            if (actionResponse.length > 1) {
                setIsLoadinButton(true);
                history.push({
                    pathname: "/selectaccounts",
                });
            } else {
                resetPasswordOnceTime(actionResponse[0].userName);
            }
        } else if (actionResponse !== undefined) {
            showWrongResponse(actionResponse);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList]);

    /**
     * This function send a email to activate account
     */
    const handleClickInLink = async () => {
        onClickLink();
        sendEmailIfIsNecesay();
    }

    const senEmailToUser = async (userName) => {
        localStorage.setItem('resend', "false");
        dispatch(SendUserActivationEmail({
            userName,
            appName,
        }, tenant));
    }

    /**
     * This function verify if user saved in local storge is not null and send email
     */
    const sendEmailIfIsNecesay = () => {
        const userName = history.location.state?.userName;
        if (userName !== undefined) {
            senEmailToUser(userName);
        }
    }

    useEffect(() => {
        if (!!getAuthenticationResponse) {
            if (getAuthenticationResponse.responseCode === 200) {
                setValidateCallBack(false);
            } else {
                setIsLoadinButton(false);
                setValidateCallBack(true);
                setErrorsValidation({
                    email: {
                        message: getAuthenticationResponse.message,
                        type: "invalid-user"
                    }
                });
            }
        }
    }, [getAuthenticationResponse]);

    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.mainComponent}
        >
            <Box m={1} pt={3}>
                <img src={theme.urlLogo[`${firstImage}`]} alt="Logo" style={{ width: theme.widthImageLogo }} />
            </Box>
            <Box m={1} pt={3}>
                <h1 style={standarStyles.firstText}>
                    {title}
                </h1>
            </Box>
            {
                secondImage && (
                    <Box m={1} pt={3}>
                        <img src={theme.urlLogo[`${secondImage}`]} alt="Logo" width={widthOfSecondImage !== '' ? widthOfSecondImage : '225px'} />
                    </Box>
                )
            }
            {hasInput &&
                <form>
                    <Box m={1} pt={3} style={{ marginTop: -10 }}>
                        <Typography className={classes.infoText}>
                            {infoText}
                        </Typography>
                    </Box>
                    <Box m={1} pt={3}>
                        <CustomInput
                            label={t("layouth-info.label-email")}
                            name="email"
                            control={control}
                            styleFromHelperText={{ style: { display: 'none', marginRight: 10 } }}
                            error={errors?.email ? errors : errorsValidation}
                            classes={classes.typeInput}
                            autoFocus={true}
                            onValidate={handleChangeOfEmail}
                        />
                    </Box>
                    {getErrors()}
                    <Box m={1} pt={3}>
                        <PrimaryButtonUkoBeMyself
                            txtBtn={t("layouth-info.button-send")}
                            loading={isLoadinButton}
                            disabled={!isValidEmail}
                            onClick={handleSubmit(handleResetPassword)}
                            isAnotherButtonDisabled={true}
                        />
                    </Box>
                </form>
            }
            {textBeforeEnd &&
                <Box m={1} pt={3}>
                    <Typography className={classes.sendEmailMessage}>
                        {textBeforeEnd}
                    </Typography>
                </Box>
            }
            {textInTheEnd &&
                <Box m={1} pt={3} fontWeight='fontWeightMedium' >
                    <Typography className={classes.sendEmailMessage}>
                        {textInTheEnd}
                    </Typography>
                </Box>
            }
            {onClickLink && textInLink ?
                <Box m={1} pt={3}>
                    <Link
                        onClick={handleClickInLink}
                        className={classes.textLink}
                    >
                        {textInLink}
                    </Link>
                </Box>
                :
                <></>
            }
            {showLoading &&
                <CircularProgress
                    size={30}
                />
            }
            <Box className={classes.versionApp}>
                <p>{versionMessage}</p>
            </Box>
        </Grid>
    );
}

LayouthInfoUkoBeMyself.propTypes = {
    resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    loadingReducer: state.loadingReducer,
    getAuthenticationResponse: state.authReducer.getAuthenticationResponse,
    getVersionApp: state.applicationsReducer.getVersionApp,
    userList: state.authReducer.userList,
});

const mapDispatchToProps = {
    resetPassword,
    getVersionAppAction,
    getUserForResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayouthInfoUkoBeMyself);


const useStyles = makeStyles((theme) => ({
    typeInput: {
        '& .MuiOutlinedInput-root': {
            position: 'relative',
            borderRadius: '8px',
            width: '486px',
            height: '56px',
            backgroundColor: theme.InputUko.backgroundColor,
            [theme.breakpoints.down("xs")]: {
                width: "320px",
            },
        },
        "& .MuiOutlinedInput-input": {
            fontWeight: 500,
            color: theme.InputUko.colorInputLetter,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
            borderColor: theme.InputUko.borderInput,
        },
        "& .MuiInputLabel-root": {
            fontWeight: 500,
            color: theme.InputUko.colorLabel,
            fontSize: '17px',
            marginTop: '7px',
        },
        "& .MuiInputLabel-root.Mui-focused": {
            fontWeight: 600,
            color: theme.InputUko.colorLabelFocus,
        },
        "& .MuiSvgIcon-root": {
            color: theme.InputUko.colorIcon,
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.dark,
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: theme.palette.error.dark,
        },
        "& .MuiFormLabel-asterisk.Mui-error": {
            color: theme.palette.error.dark,
        },
        "& .MuiInputLabel-shrink": {
            margin: "0 auto",
            position: "absolute",
            top: "1px",
        },
    },
    version: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme.versionUko.colorVersionLabel,
        bottom: '15px',
        left: '50%',
        right: '0',
        textAlign: 'center',
        position: 'static',
    },
    sendEmailMessage: {
        color: theme.palette.info.light,
        fontSize: '15px',
        fontWeight: 700,
    }, textLink: {
        textDecoration: "underline",
        color: theme.linkUko.colorLink,
        marginBottom: '2px',
        fontSize: '15px',
        fontWeight: 700
    }, mainComponent: {
        minHeight: '93vh',
        backgroundColor: theme.palette.secondary.light,
    }, infoText: {
        color: theme.palette.info.light,
        fontSize: '15px',
        fontWeight: 700,
        '& .MuiBox-root-9': {
            paddingTop: 0,
        }
    }, versionApp: {
        fontSize: '14px',
        fontWeight: 600,
        color: theme.versionUko.colorVersionLabel,
        position: 'absolute',
        bottom: '15px',
        textAlign: 'center',
    }, 
    customErrorBox: {
        width: '486px',
        [theme.breakpoints.down("xs")]: {
            width: "320px",
        },
    }
}));

