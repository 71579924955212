import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

/**
 * PrimaryButton constant ( function component used as generic primary button )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    padding: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "12px",
  },
}));

const ErrorBox = ({ ...props }) => {
  const { value, appName } = props;
  const classes = useStyles();
  return (
    <div>
      {!appName && (<br />)}
      <div className={classes.root}>
        <Typography>{value} </Typography>
      </div>
    </div>
  );
};

export default ErrorBox;
