import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getUsers, inactivate, deleteUser } from "../../actions/user.action";
import UserForm from "../users/userForm.view";
import ViewLayout from "../../components/layouts/ViewLayout";
import CustomTable from "../../components/Table/CustomTable.component";
import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import NewUserIcon from "../../components/IconsLibrary/NewUserIcon";
import { isEmpty } from "../../utils/proprietaryHooks";
import { GET_USER_UPDATED, SET_USER_DELETE } from "../../actions/types";

/**
 * Users Component ( full view for list of users)
 *
 * @export Class Component
 * @class Users
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles({
  styleFab: {
    boxShadow: "none",
  },
});

const Users = ({ component: Component, container, ...props }) => {
  const {
    userReducer,
    rows,
    getUsers,
    deleteUser,
    history,
    getUserUpdated,
    deleteResponse,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState({ id: 0 });
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    checked: false,
    delete: false,
    message: "",
  });
  const classes = useStyles();
  const [isFormShowed, setFormShowed] = useState(false);
  const [isSearch, setIsSearch] = useState();
  const [lastInfo, setLastInfo] = useState(0);
  const columns = [
    {
      id: "userName",
      label: "Nombre de usuario",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "names",
      label: "Nombres",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "surnames",
      label: "Apellidos",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "document",
      label: "Documento\u00a0",
      minWidth: 100,
      align: "center",
      haveDropDown: true,
      format: (value) =>
        !isNaN(value) ? parseFloat(value).toLocaleString("es-CO") : value,
    },
    {
      id: "email",
      label: "Correo\u00a0electrónico",
      minWidth: 170,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "idstate",
      label: "Estado",
      minWidth: 90,
      align: "center",
      haveDropDown: true,
      isVisible: (row) => {
        if(row.idTypeUser === 2 || row.idTypeUser === 4){
          return true;
        }else{
          return false;
        }
      },
      component: {
        handleChange: (e, value, id) => handleConfirmClick(e, value),
      },
      handleIconClick: (evt, id) => handleDeleteClick(evt, id),
      withIcon: true,
      tooltipMessage: "usuario",
      format: (value) => (value === 1 || value === 3 || value === 6 ? false : true),
    },
  ];
  const [option, setOption] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getUsers('',0,option);
  }, [getUsers,option]);

  useEffect(() => {
    if (!!userReducer.length) {
      setLoading(false);
    }
    setIsSearch(false);
  }, [userReducer]);

  useEffect(() => {
    if (Object.keys(deleteResponse).length) {
      if (deleteResponse.informationCode === "A10") {
        setConfirmInactivate({
          open: true,
          message: deleteResponse.information,
          checked: false,
          customFunction: () => {
            dispatch({
              type: SET_USER_DELETE,
              payload: {},
            });
            setConfirmInactivate({
              open: false,
            });
          },
        });
        getUsers('',0,null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteResponse, getUsers]);

  useEffect(() => {
    if (!isEmpty(getUserUpdated)) {
      setConfirmInactivate({
        open: true,
        message: getUserUpdated,
      });
      dispatch({
        type: GET_USER_UPDATED,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserUpdated]);

  const updateUsersTable = () => {
    getUsers('',0,null);
  };

  const handleConfirmChecked = () => {
    if (!!confirmInactivate.item) {
      setLoading(true);
      if (confirmInactivate.delete) {
        deleteUser({
          Id: confirmInactivate.item,
        });
      } else {
        props.inactivate(
          { id: confirmInactivate.item, idstate: confirmInactivate.checked },
          updateUsersTable,
          history
        );
      }
      setConfirmInactivate({
        open: false,
        checked: false,
        item: null,
      });
    } else {
      setConfirmInactivate({
        open: false,
        checked: false,
      });
    }
  };

  const handleConfirmClick = (e, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      showBtnAccept: true,
      showBtnCancel: true,
      delete: false,
      checked: e.target.checked,
      message: `¿Deseas ${
        e.target.checked ? "activar" : "desactivar"
      } este usuario ?`,
    });
  };
  const handleSearchUsers = (name) => {
    getUsers(name,lastInfo)
  };
  /**
   *  Maneja el evento click de el icono basurero
   * @param {*} evt
   * @param {*} id
   */
  const handleDeleteClick = (evt, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      checked: evt.target.checked,
      delete: true,
      showBtnAccept: true,
      showBtnCancel: true,
      message: `¿Deseas eliminar este usuario?`,
    });
  };

  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          lg: 7,
          md: 12,
          sm: 12,
        },
      },
      menuOrder: {
        options: [
          {
            label: "Activo",
            columns: [
              {
                prop: "idstate",
                direction: -1,
              },
            ],
          },
          {
            label: "Inactivo",
            columns: [
              {
                prop: "idstate",
              },
            ],
          },
          {
            label: "Más reciente",
            columns: [
              {
                prop: "modifiedon",
                direction: 1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "modifiedon",
                direction: -1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
        ],
        cellSize: {
          lg: 6,
          md: 2,
          sm: 2,
        },
      },
      // exportButton: {
      //   cellSize: {
      //     lg: 6,
      //     md: 2,
      //     sm: 2
      //   }
      // },
      children: () => {
        return (
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={2}
            xs={2}
            direction="row-reverse"
          >
            <Tooltip title={`Crear usuario`} aria-label={`Nuevo usuario`}>
              <Fab
                className={classes.styleFab}
                size="small"
                onClick={() => {
                  setFormShowed(true);
                  setUser({
                    id: 0,
                  });
                }}
              >
                <NewUserIcon />
              </Fab>
            </Tooltip>
          </Grid>
        );
      },
    };

    return button;
  };

  const handleCellClick = (evt, row) => {
    setUser(row);
    // setLoading(true);
    setFormShowed(true);
  };
  const handleChangePages = (_event, newPage, rowsPerPage,search) => {
    const numRows = rowsPerPage;
    let last;
    if(search === ''){
      last = userReducer.length;
      setLastInfo(last);
      if (!isSearch && (last < (newPage + 1) * numRows)) {
        getUsers('',lastInfo,option);
      }
    } 
  };

  const closeAlert = () => {
    setConfirmInactivate({
      open: false,
      checked: false,
    });
  };

  return (
    <ViewLayout
      headerTitle={"Usuarios"}
      handleAccept={handleConfirmChecked}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      handleConfirmClose={closeAlert}
      isLoading={isLoading}
    >
      {isFormShowed ? (
        <UserForm
          user={user}
          setUser={setUser}
          setCardState={setFormShowed}
          setLoading={setLoading}
        />
      ) : (
        <CustomTable
          data={userReducer}
          rows={rows}
          columns={columns}
          mainParam={"names"}
          havePagination={true}
          isUser={true}
          buttons={getButtons()}
          handleCellClick={handleCellClick}
          option={option}
          setOption={setOption}
          paramToDisable={"idstate"}
          handleChangePages={handleChangePages}
          handleSearchUsers={handleSearchUsers}
        />
      )}
    </ViewLayout>
  );
};

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  inactivate: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userReducer: state.userReducer.getUsersResponse,
  rows: state.userReducer.rows,
  inactivate: state.userReducer.inactivateResponse,
  deleteResponse: state.userReducer.deleteResponse,
  getUserChangedResponse: state.userReducer.getUserChangedResponse,
  getUserUpdated: state.userReducer.getUserUpdated,
});

const mapDispatchToProps = {
  getUsers,
  inactivate,
  deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
