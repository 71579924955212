import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Header from '../components/Header'

export const PublicRoute = ({component: Component,isAuth, appName,...rest}) => {
    return (
        <Route
            {...rest}
            render={ props => 
            !isAuth ? (
                <>
                    {
                        appName === 'bemyself' && (
                            <Header />
                        )
                    }
                    <Component {...props} />
                </>
            ) : (
                <Redirect to="/" />
            )
            }
        />
    )
}
